import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class OutboundService {
  private API_URL = environment.API_URL;
  constructor(private _http: HttpClient) { }

  getOutboundListing(data): Observable<any> {
    // tslint:disable-next-line: no-console
    return this._http.post(this.API_URL + `/api/bpo/listing/outbound-listing`, data);
  }

  getCsmsData(): Observable<any> {
    return this._http.get(this.API_URL + '/api/bpo/listing/csm-data');
  }

  getCsmSchemeSkuData(data): Observable<any> {
    return this._http.get(this.API_URL + '/api/bpo/listing/csm-scheme-skus-data?user_id=' + data.user_id);
  }

  getCallStatusData(): Observable<any> {
    return this._http.get(this.API_URL + '/api/bpo/listing/bpo/call-statuses');
  }

  getSchemesData(): Observable<any> {
    return this._http.get(this.API_URL + '/api/listing/total-schemes-list');
  }

  getSkusData(id): Observable<any> {
    return this._http.get(this.API_URL + '/api/listing/scheme/' + id + '/skus');
  }
}
