import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment'

@Injectable({
  providedIn: 'root'
})
export class ProductsService {

  constructor(private http: HttpClient) { }
  private API_URL = environment.API_URL;

  getProductGroups(){
    return this.http.get(`${this.API_URL}/productgroups/view`, { observe: 'response'})
  }
  gettotalSchemeDatas(id){
    return this.http.get(`${this.API_URL}/api/crystal/schemes/${id}`, { observe: 'response'})
  }
  getProductGroupInfo(productgroup_id){
    return this.http.get(`${this.API_URL}/productgroups/view/${productgroup_id}`,{ observe: 'response'})
  }

  createPoductGroup(data){
    return this.http.post(`${this.API_URL}/productgroups/create`,data, { observe: 'response'})
  }

  updateProductGroup(data){
    return this.http.put(`${this.API_URL}/productgroups/update`,data,{ observe: 'response'})
  }

  getSbus(){
    return this.http.get(`${this.API_URL}/sbus/view`, { observe: 'response'})
  }

  getSkus(){
    return this.http.get(`${this.API_URL}/skus/view`, { observe: 'response'})
  }
  getallCrystalSchems(){
    return this.http.get(`${this.API_URL}/api/crystal/schemes/view`, { observe: 'response'})
  }

  getSkuInfo(sku_id){
    return this.http.get(`${this.API_URL}/skus/view/${sku_id}`,{ observe: 'response'})
  }

  createSku(data){
    return this.http.post(`${this.API_URL}/skus/create`,data, { observe: 'response'})
  }
  savecrisyaltypes(data){
    return this.http.post(`${this.API_URL}/api/crystal/schemes/create`,data, { observe: 'response'})
  }
  
  updateSku(data){
    return this.http.put(`${this.API_URL}/skus/update`,data,{ observe: 'response'})
  }

  getProducts(){
    return this.http.get(`${this.API_URL}/products/view`, { observe: 'response'})
  }

  getProductInfo(product_id){
    return this.http.get(`${this.API_URL}/products/view/${product_id}`,{ observe: 'response'})
  }

  createPoduct(data){
      return this.http.post(`${this.API_URL}/products/create`,data, { observe: 'response'})
  }

  updateProduct(data){
    return this.http.put(`${this.API_URL}/products/update`,data,{ observe: 'response'})
  }
  getProductGroup() {
    return this.http.get(`${this.API_URL}/api/listing/productgroups`);
  }
  getProductsByProductGroupId(id) {
    return this.http.get(`${this.API_URL}/api/listing/${id}/products`,{ observe: 'response'});
  }
  getSkusByProductId(id: number) {
    return this.http.get(`${this.API_URL}/api/listing/${id}/skus`,{ observe: 'response'});
  }

  getRetailers(){
    return this.http.get(`${this.API_URL}/api/crystal/schemes/retailers/list`,{ observe: 'response'});

  }

  postRetailers(data){
    return this.http.post(`${this.API_URL}/api/crystal/schemes/assign-retailers`,data, { observe: 'response'})
  }

  getRetailersSales(id){
    return this.http.get(`${this.API_URL}/api/report/retailer-sales-points/${id}`,{ observe: 'response'});
  }

  getSchemes(){
    return this.http.get(`${this.API_URL}/api/crystal/schemes/list`,{ observe: 'response'});

  }

  
}
