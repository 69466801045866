import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Scheme, Role } from '../models/reward-disbusment';
import { environment } from './../../../environments/environment';
import { Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class LuckydrawService {
  apiURL: string = environment.API_URL;
  params: HttpParams = new HttpParams();
  constructor(private http: HttpClient) { }
  _getroles(): Observable<any> {
    return this.http.get(this.apiURL + '/api/listing/roles', { observe: 'response' });
  }
  _getSchemes(roleId): Observable<any> {
    return this.http.get(this.apiURL + '/api/listing/active-schemes?roleId='+roleId, { observe: 'response' });
  }
  _getSchemestypes(roleId): Observable<any> {
    return this.http.post(this.apiURL + '/api\/listing\/scheme\/scheme-types',{"schemeIds" : roleId}, { observe: 'response' });
  }
  _getskus(schemeIds,schemeTypeIds): Observable<any> {
    return this.http.post(this.apiURL + '/api/listing/scheme/scheme-type/skus',{"schemeIds" :schemeIds,"schemeTypeIds" :schemeTypeIds}, { observe: 'response' });
  }
  _getAllSchemes(): Observable<any> {
    return this.http.get(this.apiURL + '/api/listing/active-lucky-draw-schemes', { observe: 'response' });
  }
  _createLuckyDraw(obj): Observable<any> {
    return this.http.post(this.apiURL + '/api/lucky-draw/create',obj, { observe: 'response' });
  }
  
  _getluckyDrawlist(): Observable<any> {
    return this.http.get(this.apiURL + '/api/lucky-draw/list', { observe: 'response' });
  }
  _getElegibleUsers(obj,params): Observable<any> {
    return this.http.post(this.apiURL + '/api/lucky-draw/eligible-users',obj, { params:params, observe: 'response' });
  }
  _getAlleligibleSchems(): Observable<any> {
    return this.http.get(this.apiURL + '/api/listing/active-lucky-draw-schemes', { observe: 'response' });
  }
  _getLcation_types(roleId): Observable<any> {
    return this.http.get(this.apiURL + '/api/listing/role/location-types?roleId='+roleId, { observe: 'response' });
  }
  _getCountries(obj): Observable<any> {
    return this.http.post(this.apiURL + '/api/listing/locations',obj, { observe: 'response' });
  }
  _getStaes(obj): Observable<any> {
    return this.http.post(this.apiURL + '/api/listing/locations',obj, { observe: 'response' });
  }
  _getCities(obj): Observable<any> {
    return this.http.post(this.apiURL + '/api/listing/locations',obj, { observe: 'response' });
  }
  _getDistrics(obj): Observable<any> {
    return this.http.post(this.apiURL + '/api/listing/locations',obj, { observe: 'response' });
  }
  _getAreas(obj): Observable<any> {
    return this.http.post(this.apiURL + '/api/listing/locations',obj, { observe: 'response' });
  }
  _downloadwinners(obj): Observable<any> {
    return this.http.post(this.apiURL + '/api/lucky-draw/eligible-users/download',obj, { observe: 'response' });
  }
  _downloadwinners2(obj): Observable<any> {
    return this.http.post(this.apiURL + '/api/lucky-draw/winners/download',obj, { observe: 'response' });
  }
  _getluckywinners(roleId): Observable<any> {
    return this.http.get(this.apiURL + '/api/lucky-draw/'+roleId+'/winners', { observe: 'response' });
  }
  _postpickwinners(obj): Observable<any> {
    return this.http.post(this.apiURL + '/api/lucky-draw/pick-winners',obj, { observe: 'response' });
  }
}
