import { Injectable } from '@angular/core';
import { environment } from './../../../environments/environment';
import {
  ProductGroup, RedumptionType, RewardActivity, RewardCatalogue,
  LocationType, Countries
} from '../models/schemes';
import { Role } from '../models/reward-disbusment';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})

export class SchemesService {
  // apiURL: string = environment.API_URL;
  private API_URL = environment.API_URL;
  private product_group_list: ProductGroup[] = [];
  private redumption_type_list: RedumptionType[] = [];

  constructor(private http: HttpClient) { }

  getSchemesList(): Observable<any> {
    return this.http.get(`${this.API_URL}/api/schemes/view`, { observe: 'response' });
  }

  getSkulistBySchemeId(id: number): Observable<any> {
    return this.http.get(`${this.API_URL}/api/schemes/${id}/skus`, { observe: 'response' });
  }

  getAllSkuList(): Observable<any> {
    return this.http.get(`${this.API_URL}/api/schemes/sku/all`, { observe: 'response' });
  }
  getSkuDetailsById(id: number): Observable<any> {
    return this.http.get(`${this.API_URL}/api/schemes/sku/${id}/view`, { observe: 'response' });
  }
  getProductGroup() {
    return this.http.get<ProductGroup[]>(`${this.API_URL}/api/listing/productgroups`);
  }
  getProductsByProductGroupId(id: number) {
    return this.http.get(`${this.API_URL}/api/listing/${id}/products`);
  }
  getSkusByProductId(id: number) {
    return this.http.get(`${this.API_URL}/api/listing/${id}/skus`);
  }
  getRedumptionType() {
    return this.http.get<RedumptionType[]>(`${this.API_URL}/api/listing/redemption-types`);
  }
  getTargetAuidence() {
    return this.http.get<Role[]>(`${this.API_URL}/api/listing/roles`);
  }
  getRewardActivity() {
    return this.http.get<RewardActivity[]>(`${this.API_URL}/api/listing/scheme-types`);
  }
  getRewardCatalogue() {
    return this.http.get<RewardCatalogue[]>(`${this.API_URL}/api/listing/reward-catalogues`);
  }
  getRewardTypeByCatalogueId(id: number) {
    return this.http.get(`${this.API_URL}/api/listing/${id}/reward-types`);
  }
  getRewardsByType(data) {
    return this.http.post(`${this.API_URL}/api/listing/rewards`, data);
  }
  getLocationType() {
    return this.http.get<LocationType[]>(`${this.API_URL}/api/listing/location-types`);
  }
  getCountries() {
    return this.http.get<Countries[]>(`${this.API_URL}/api/listing/countries`);
  }
  getStatesByCountry(data) {
    return this.http.post(`${this.API_URL}/api/listing/states`, data);
  }
  getCitiesByStates(data) {
    return this.http.post(`${this.API_URL}/api/listing/cities`, data);
  }
  postCreateScheme(schemeData) {
    return this.http.post(`${this.API_URL}/api/schemes/add`, schemeData);
  }
  postCreateSku(schemeData) {
    return this.http.post(`${this.API_URL}/api/schemes/sku/add`, schemeData);
  }
}
