import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CsmService {
  private API_URL = environment.API_URL;

  constructor(private _http: HttpClient) { }
  getCsmData(): Observable<any> {
    return this._http.get(this.API_URL + '/api/bpo/listing/csm-data');
  }
  schemeCsmData(id): Observable<any> {
    return this._http.get(this.API_URL + `/api/bpo/listing/csm-data/${id}`);
  }
  createCsm(data): Observable<any> {
    return this._http.post(this.API_URL + `/api/csm/create`, data,{ observe: 'response' });
  }
  assignCalls(data): Observable<any> {
    return this._http.post(this.API_URL + `/api/csm/assign-calls`, data);
  }
  getSchemeCircles(id): Observable<any> {
    return this._http.get(this.API_URL + `/api/bpo/listing/${id}/circles`);
  }
  getSchemeCsms(id): Observable<any> {
    return this._http.get(this.API_URL + `/api/bpo/listing/csm-data/${id}`);
  }

  nonAssignedCsms(id): Observable<any> {
    return this._http.get(this.API_URL + `/api/bpo/listing/${id}/not-assigned-csms`);
  }
  assignCsm(data): Observable<any> {
    return this._http.post(this.API_URL + `/api/csm/assign-csm`, data);
  }
  getSchemes(): Observable<any> {
    return this._http.get(this.API_URL + `/api/bpo/listing/schemes`);
  }
}
