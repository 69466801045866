import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class NotificationsService {
  private API_URL = environment.API_URL;

  constructor(private http: HttpClient) { }


  getNotificationsList(params): Observable<any> {
    return this.http.get(this.API_URL + '/api/notifications/list',{ params:params, observe: 'response' });
  }

  postSendNotications(obj): Observable<any> {

    return this.http.post(`${this.API_URL}/api/notifications/send`, obj,
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        })
      })
  }

  getSampleDowloadFile(): Observable<any> {

    return this.http.get(`${this.API_URL}/api/notifications/sample-file`,
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/octet-stream',
        }), responseType: 'blob'
      })
  }

}
